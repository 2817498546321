var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":_vm.outlined}},[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Top ingrédients")])],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"csvButton":{
        csvData: _vm.csvData,
        filename: ("top-ingredients-" + (this.startDate) + "_" + (this.endDate) + ".csv"),
        disabled: _vm.exportDisabled,
      },"excelButton":{
        excelData: _vm.excelData,
        filename: ("top-ingredients-" + (this.startDate) + "_" + (this.endDate) + ".xlsx"),
        disabled: _vm.exportDisabled,
      }},on:{"exportCsv":_vm.exportCSV,"exportExcel":_vm.exportExcel}})],1),(_vm.useMarmitonFilters)?_c('v-card-text',{staticClass:"align-start"},[_c('ingredient-filter',{staticClass:"mr-4",attrs:{"label":"Ingrédients à exclure","storeGetter":"getMarmitonTopIngredientsToExclude","storeUpdater":"updateMarmitonTopIngredientsToExclude"}})],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{class:_vm.classname,attrs:{"disable-filtering":true,"disable-sort":true,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"page":_vm.options.page,"pageCount":_vm.numberOfPages,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }